<template>
  <b-modal
    v-model="customerSend.modalStatus"
    title="Müşteriye Gönder"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    centered
    size="lg"
  >
    <template v-if="customerAccounts.length > 0">
      <b-alert
        v-if="customerSendResult.message"
        show
        :variant="customerSendResult.status === true? 'success' : 'danger'"
      >
        <div class="alert-body text-center">
          {{ customerSendResult.message }}
        </div>
      </b-alert>
      <div v-else>
        <template v-if="customerSend.sending">
          <pre-loading />
        </template>
        <template v-else>
          <div class="font-weight-bold mb-1">
            Gönderilecek Müşteri Hesapları
          </div>
          <b-list-group class="mb-1">
            <b-list-group-item
              v-for="item in customerAccounts"
              :key="item.id"
            >
              <b-form-checkbox
                v-model="customerSend.to"
                :value="item.email"
              >
                <div class="font-weight-bold">
                  {{ item.title }}
                </div>
                <div class="font-small-2 text-primary">
                  {{ item.email }}
                </div>
              </b-form-checkbox>
            </b-list-group-item>
          </b-list-group>
          <b-form-group
            label="Mesajınız"
            label-for="message"
          >
            <b-form-textarea
              id="message"
              v-model="customerSend.message"
              placeholder="Mesajınız"
            />
          </b-form-group>
          <b-button
            variant="primary"
            :disabled="!customerSend.to.length"
            @click="submitCatalog"
          >
            <FeatherIcon icon="SendIcon" />
            Gönder
          </b-button>
        </template>
      </div>
    </template>
    <template v-else>
      <b-alert
        show
        variant="info"
      >
        <div class="alert-body text-center">
          <div class="lead">
            Firma için aktif kullanıcı hesabı bulunamadı.
          </div>
          <div class="mt-1">
            <b-button
              variant="info"
              size="sm"
              :to="'/app/customers/accounts/add/' + dataItem.id_customers"
            >
              Hesap Oluştur
            </b-button>
          </div>
        </div>
      </b-alert>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BListGroup, BListGroupItem, BFormCheckbox, BFormGroup, BFormTextarea, BButton, BAlert,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'CustomerSendModal',
  components: {
    BModal,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BButton,
    BAlert,
    PreLoading,
  },
  computed: {
    dataItem() {
      return this.$store.getters['catalogs/dataItem']
    },
    customerSend() {
      return this.$store.getters['catalogs/customerSend']
    },
    customerSendResult() {
      return this.$store.getters['catalogs/customerSendResult']
    },
    customerAccounts() {
      return this.$store.getters['customerAccounts/dataList']
    },
  },
  mounted() {
    this.resetForm()
  },
  methods: {
    resetForm() {
      this.customerSend.to = []
      this.customerSend.message = null
      this.customerSend.sending = false
      this.customerSendResult.status = null
      this.customerSendResult.message = null
    },
    submitCatalog() {
      this.customerSend.sending = true
      this.$store.dispatch('catalogs/customerSend', {
        to: this.customerSend.to,
        message: this.customerSend.message,
        id: this.dataItem.id,
      })
    },
  },
}
</script>
