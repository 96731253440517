<template>
  <b-modal
    v-model="testResultModal.modalStatus"
    hide-footer
    :title="testResultModal.product + ' - Test Sonuçları'"
  >
    <b-list-group>
      <b-list-group-item>
        <div>
          <div class="text-primary font-weight-bold">
            Martindale / Cycle
          </div>
          <div v-if="testResultModal.data.asinma">
            {{ testResultModal.data.asinma | toNumber }}
          </div>
          <div v-else>
            -
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          <div class="text-primary font-weight-bold">
            Kopuş
          </div>
          <div v-if="testResultModal.data.asinma_sonuc">
            <template v-if="testResultModal.data.asinma_sonuc === '1'">
              VAR
            </template>
            <template v-else-if="testResultModal.data.asinma_sonuc === '0'">
              YOK
            </template>
            <template v-else>
              {{ testResultModal.data.asinma_sonuc }}
            </template>
          </div>
          <div v-else>
            -
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          <div class="text-primary font-weight-bold">
            Pilling / Cycle
          </div>
          <div v-if="testResultModal.data.pilling">
            {{ testResultModal.data.pilling | toNumber }}
          </div>
          <div v-else>
            -
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          <div class="text-primary font-weight-bold">
            Pilling Değeri
          </div>
          <div v-if="testResultModal.data.pilling_sonuc">
            {{ testResultModal.data.pilling_sonuc }}
          </div>
          <div v-else>
            -
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          <div class="text-primary font-weight-bold">
            Renk Haslığı Değeri / Kuru
          </div>
          <div v-if="testResultModal.data.rh_kuru">
            {{ testResultModal.data.rh_kuru }}
          </div>
          <div v-else>
            -
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          <div class="text-primary font-weight-bold">
            Renk Haslığı Değeri / Yaş
          </div>
          <div v-if="testResultModal.data.rh_yas">
            {{ testResultModal.data.rh_yas }}
          </div>
          <div v-else>
            -
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          <div class="text-primary font-weight-bold">
            Hav Kaybı Başlangıç Değeri
          </div>
          <div v-if="testResultModal.data.hav_kaybi">
            {{ testResultModal.data.hav_kaybi }}
          </div>
          <div v-else>
            -
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>
<script>
import { BModal, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'ModalTestResult',
  components: {
    BModal,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    testResultModal() {
      return this.$store.getters['catalogs/testResultModal']
    },
  },
}
</script>
