<template>
  <b-row
    v-if="dataItem.items.length > 0"
  >
    <b-col
      v-for="(item, index) in dataItem.items"
      :key="index"
      cols="12"
      md="3"
    >
      <div
        class="border-light rounded p-1 mb-2 bg-light"
      >
        <div
          class="height-auto overflow-hidden"
        >
          <swiper
            v-if="item.images.length"
            class="swiper-navigations"
            :options="swiperOptions"
          >
            <swiper-slide
              v-for="(img,index) in item.images"
              :key="index"
            >
              <b-img
                :src="baseURL + '/media/products/' + img.thumb"
                fluid
                class="h-auto w-100 rounded"
              />
            </swiper-slide>
            <div
              slot="pagination"
              class="swiper-pagination"
            />
          </swiper>
          <empty-image
            v-else
            class="mx-auto"
          />
          <div class="text-center mt-1">
            <div class="font-weight-bolder text-nowrap">
              {{ item.product }}
            </div>
            <div class="font-small-2 text-muted">
              {{ item.product_quality }}
            </div>
            <div class="text-primary">
              {{ item.price | toCurrency }} {{ item.currency }}
            </div>
            <div class="border-top pt-1 mt-1">
              <b-button
                :variant="item.tests.id? 'success' : 'flat-warning'"
                size="sm"
                pill
                block
                :disabled="!item.tests.id"
                @click="setModal(item)"
              >
                {{ item.tests.id ? 'Test Sonuçlarını İncele' : 'Test Bulunamadı.' }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-col>
    <b-col cols="12">
      <b-alert
        show
        variant="info"
      >
        <div class="alert-body">
          <FeatherIcon icon="InfoIcon" /> Ürünleri fiyatları müşteri için hazırlanan fiyat listesinden oluşmaktadır.
        </div>
      </b-alert>
    </b-col>
    <modal-test-result />
  </b-row>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import {
  BImg, BAlert, BRow, BCol, BButton,
} from 'bootstrap-vue'
import EmptyImage from '@/layouts/components/common/EmptyImage.vue'
import ModalTestResult from '@/views/Admin/Catalogs/elements/ModalTestResult.vue'

export default {
  name: 'Products',
  components: {
    BImg,
    BAlert,
    BRow,
    BCol,
    BButton,
    EmptyImage,
    Swiper,
    SwiperSlide,
    ModalTestResult,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      swiperOptions: {
        effect: 'cube',
        grabCursor: true,
        cubeEffect: {
          shadow: false,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['catalogs/dataItem']
    },
    testResultModal() {
      return this.$store.getters['catalogs/testResultModal']
    },
  },
  methods: {
    setModal(data) {
      this.testResultModal.modalStatus = true
      this.testResultModal.product = data.product
      this.testResultModal.data = data.tests
    },
  },
}
</script>
<style>
.swiper-pagination-bullet-active{
  background: #cccccc !important;
}
</style>
