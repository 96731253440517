<template>
  <b-row>
    <b-col
      v-for="item in dataList"
      :key="item.id"
      cols="12"
      md="3"
    >
      <div class="border p-1 rounded text-center mb-3">
        <div class="overflow-hidden rounded">
          <b-img
            :src="baseURL + '/media/products/' + item.image"
            fluid-grow
          />
        </div>
        <div class="text-primary font-weight-bold mt-1">
          {{ item.product }}
        </div>
        <div class="font-small-2 d-flex justify-content-center">
          <div
            v-if="item.like_it === '1'"
            class="d-flex align-items-center text-success"
          >
            <FeatherIcon icon="ThumbsUpIcon" /> Beğendi
          </div>
          <div
            v-else-if="item.dislike_it === '1'"
            class="d-flex align-items-center text-danger"
          >
            <FeatherIcon icon="ThumbsDownIcon" /> Beğenmedi
          </div>
          <div
            v-else
            class="d-flex align-items-center text-muted"
          >
            Etkileşim bulunamadı.
          </div>
        </div>
        <div class="font-weight-bolder text-info">
          Görüntüleme: {{ item.watch_counts }}
        </div>
        <div class="font-small-2 text-warning">
          <div>Son Görüntüleme Tarihi</div>
          <div>
            {{ moment(item.last_watch).format('llll') }}
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BImg } from 'bootstrap-vue'

export default {
  name: 'SummaryCard',
  components: {
    BRow, BCol, BImg,
  },
  props: {
    dataList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
    }
  },
}
</script>
