<template>
  <app-collapse
    accordion
    type="border"
  >
    <app-collapse-item
      v-for="(item,key) in dataList"
      :key="item.id"
      :title="moment(item.watch_date).format('LLLL')"
      :action-params="item.id_catalog_tracings"
      @getDetail="getData"
    >
      <pre-loading v-if="detailLoading" />
      <b-table
        :fields="fields"
        :items="detailList"
        striped
        responsive="sm"
        borderless
      >
        <template #cell(watch_date)="data">
          <div>
            {{ moment(data.item.watch_date).format('llll') }}
          </div>
        </template>
        <template #cell(linesCount)="data">
          <b-badge variant="primary">
            {{ data.item.linesCount }}
          </b-badge>
        </template>
      </b-table>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import { BTable, BBadge } from 'bootstrap-vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'DislikeCard',
  components: {
    AppCollapse,
    AppCollapseItem,
    PreLoading,
    BTable,
    BBadge,
  },
  props: {
    dataList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'product',
          label: 'ÜRÜN',
        },
        {
          key: 'watch_date',
          label: 'TARİH',
          thClass: 'text-nowrap text-center width-200',
          tdClass: 'text-nowrap text-center width-200',
        },
        {
          key: 'linesCount',
          label: 'GÖRÜNTÜLEME',
          thClass: 'text-nowrap text-center width-200',
          tdClass: 'text-nowrap text-center width-200',
        },
      ],
    }
  },
  computed: {
    detailList() {
      return this.$store.getters['catalogTracings/detailList']
    },
    detailLoading() {
      return this.$store.getters['catalogTracings/detailLoading']
    },
  },
  methods: {
    getData(id) {
      this.$store.dispatch('catalogTracings/getDetailList', {
        id_catalogs: this.$route.params.id,
        id_catalog_tracings: id,
      })
    },
  },
}
</script>
