<template>
  <div>
    <b-card v-if="loading">
      <pre-loading />
    </b-card>
    <template v-else>
      <b-card title="Katalog Bilgisi">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <div class="font-weight-bold text-primary">
              Katalog Adı
            </div>
            <div>{{ dataItem.title }}</div>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <div class="font-weight-bold text-primary">
              Katalog Geçerlilik Tarihi
            </div>
            <div>{{ moment(dataItem.expiry_date).format('LL') }}</div>
            <div class="text-warning font-small-2">
              <span v-if="moment(dataItem.expiry_date).diff(moment(),'days') > 0">
                {{ moment(dataItem.expiry_date).diff(moment(),'days') }} gün kaldı.
              </span>
              <span v-else>Süresi doldu</span>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <div class="font-weight-bold text-primary">
              Katalog Geçerlilik Tarihi
            </div>
            <div>{{ dataItem.catalog_status }}</div>
          </b-col>
          <b-col
            v-if="dataItem.content"
            cols="12"
          >
            <div class="font-weight-bold text-primary">
              Metin
            </div>
            <div v-html="dataItem.content" />
          </b-col>
          <b-col
            v-if="dataItem.notes"
            cols="12"
          >
            <b-alert
              variant="warning"
              show
            >
              <div class="alert-heading">
                Temsilci Notu
              </div>
              <div class="alert-body">
                {{ dataItem.notes }}
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-between">
          <b-button
            variant="warning"
            :to="'/app/catalogs/edit/' + dataItem.id"
          >
            <FeatherIcon icon="EditIcon" />
            Güncelle
          </b-button>
          <b-button
            variant="success"
            @click="submitCustomer"
          >
            <FeatherIcon icon="SendIcon" />
            Müşteriye Gönder
          </b-button>
        </div>
      </b-card>
      <b-card no-body>
        <b-card-header>
          <b-card-title>Ürünler</b-card-title>
        </b-card-header>
        <b-card-body>
          <products />
        </b-card-body>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataItem.itemsCount"
            :per-page="perPage"
            first-number
            last-number
            align="center"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
      <save-button
        :save-action="submitForm"
        :is-disabled="submitStatus"
      />
      <customer-send-modal />
    </template>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BAlert, BButton, BPagination, BCardFooter,
} from 'bootstrap-vue'
import Products from '@/views/Admin/Catalogs/elements/CatalogView/Products.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
import CustomerSendModal from '@/views/Admin/Catalogs/elements/CatalogView/CustomerSendModal.vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'CatalogForm',
  components: {
    BCardFooter,
    BPagination,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAlert,
    BButton,
    Products,
    SaveButton,
    CustomerSendModal,
    PreLoading,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
    submitStatus: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 52,
      start: 0,
      loading: true,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['catalogs/dataItem']
    },
    customerSend() {
      return this.$store.getters['catalogs/customerSend']
    },
  },
  watch: {
    currentPage(val) {
      this.start = (val - 1) * this.perPage
      this.getData()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      this.$store.dispatch('catalogs/getDataItem', { id: this.$route.params.id, limit: this.perPage, start: this.start })
        .then(response => {
          if (response.id_customers) {
            this.$store.dispatch('customers/getDataItem', response.id_customers)
            this.loading = false
          }
        })
    },
    submitCustomer() {
      this.customerSend.modalStatus = true
      this.getCustomerAccounts()
    },
    getCustomerAccounts() {
      this.$store.dispatch('customerAccounts/getDataList', {
        select: [
          'customer_accounts.id AS id',
          'customer_accounts.fullname AS title',
          'customer_accounts.email AS email',
        ],
        where: {
          'customer_accounts.id_customers': this.dataItem.id_customers,
          'customer_accounts.status': 1,
        },
      })
    },
  },
}
</script>
