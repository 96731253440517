<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Katalog Bilgileri</span>
        </template>
        <validation-observer ref="simpleRules">
          <catalog-view
            :submit-form="submitForm"
            :submit-status="submitStatus"
          />
        </validation-observer>
      </b-tab>
      <b-tab
        :disabled="dataItem.id_catalog_statuses === '1'"
        lazy
      >
        <template #title>
          <feather-icon icon="EyeIcon" />
          <span>Müşteri İzleme</span>
        </template>
        <tracings />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Bilgileri</span>
        </template>
        <customer-info />
        <customer-invoice />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-history :customer-id="this.$route.params.id_customers" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import CatalogView from '@/views/Admin/Catalogs/CatalogView.vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerHistory from '@/views/Admin/Customers/View/CustomerHistory.vue'
import Tracings from '@/views/Admin/Catalogs/elements/CatalogView/Tracings.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Add',
  components: {
    BTabs,
    BTab,
    CatalogView,
    CustomerInfo,
    CustomerInvoice,
    CustomerHistory,
    Tracings,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['catalogs/dataItem']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    submitForm() {

    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
